import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { InputLabel } from "@material-ui/core";
import styles from "../../jss/landing-webdesign.js";
import TextField from "@material-ui/core/TextField";
import InfoArea from "../../components/InfoArea/InfoArea.js";

import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";

const useStyles = makeStyles(styles);
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section2, classes.sectionDark)}>
      <Grid container spacing={2}>
        <Grid item md={6} sm={6}>
          <h1 className={classes.title}>Contact Us</h1>
          <p>
            We are available to help you with your plumbing needs. Contact us to
            discuss your project in greater detail. We
            {"'"}ll get in touch with you as soon as possible.
            <br />
            <br />
          </p>
          <form action="https://formspree.io/f/xleopdnv" method="POST">
            <Grid container spacing={2}>
              <Grid
                item
                xs={10}
                sm={10}
                lg={12}
                md={6}
                style={{ marginTop: "50px" }}
              >
                <TextField
                  id="outlined-email-input"
                  label="Email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  variant="outlined"
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={10}
                sm={10}
                md={8}
                lg={12}
                style={{ marginTop: "50px" }}
              >
                <InputLabel htmlFor="contact-message-form">Message</InputLabel>
                <TextField
                  name="message"
                  type="text"
                  id="message"
                  fullWidth={true}
                  multiline={true}
                  rows={2}
                />
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: "20px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                style={{ backgroundColor: "#0099CC", color: "white" }}
                variant="contained"
                label="Submit"
                type="submit"
              >
                Get Started
              </Button>
            </div>
          </form>
        </Grid>
        <Grid item md={4} sm={4} className={classes.mlAuto}>
          <a href="https://www.google.com/maps/place/Atlas+Agency/@43.7272885,-79.4098196,12z/data=!4m8!1m2!2m1!1satlas+agency!3m4!1s0x89d4cd18b71439cd:0x71c530c678f0d70c!8m2!3d43.7271821!4d-79.3396028">
            <InfoArea
              className={classes.info}
              title="Location"
              description={
                <p style={{ textAlign: "left" }}>
                  895 Don Mills Road, Two Morneau Shepell Centre, Suite 900{" "}
                  <br /> Toronto, Ontario, M3C 1W3 <br /> Canada
                </p>
              }
              icon={PinDrop}
              iconColor="primary"
            />
          </a>
          <InfoArea
            className={classes.info}
            title="Call"
            description={
              <p style={{ textAlign: "left" }}>
                <a
                  href="tel:+16473710263"
                  style={{ color: "black", fontSize: "20px" }}
                >
                  647-370-9868
                </a>
                <br /> 24/7 Emergency Plumber
              </p>
            }
            icon={Phone}
            iconColor="primary"
          />
        </Grid>
      </Grid>
    </div>
  );
}
