import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import styles from "../../jss/productStyle.js";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  section2: {
    padding: "70px 0",
  },
  paragraph2: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (min-width:1920px)": {
      marginTop: "10px",
    },
  },
  imageContainer: {
    padding: "60px 60px 0px 60px",
    marginTop: "40px",
    height: "400px",
    width: "400px",
    "@media screen and (max-width:500px)": {
      padding: "0px 60px 0px 60px",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "1",
    },
  },
  orderContainer: {
    "@media screen and (max-width:500px)": {
      order: "2",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "2",
    },
  },
  title90: {
    fontSize: "60px",
    color: "black !important",
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "45px",
    textDecoration: "none",
    fontWeight: "500",
    fontFamily: "poppins",
    "@media screen and (max-width:500px)": {
      fontSize: "28px",
      textAlign: "center",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      fontSize: "30px",
      textAlign: "center",
    },
  },
});

const center = {
  lat: 43.6383981,
  lng: -79.4213668,
};
const containerStyle = {
  position: "relative",
  height: "400px",
  width: "90%",
  textAlign: "center",
  borderRadius: "5px",
};

const containerStyleDesktop = {
  position: "relative",
  height: "450px",
  width: "60%",
  textAlign: "center",
  borderRadius: "5px",
};

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div style={{ overflowY: "hidden", marginRight: "0px" }}>
      <Grid
        container
        justify="center"
        direction="column"
        alignItems="center"
        align="center"
      >
        <Grid
          item
          xs={10}
          sm={12}
          md={10}
          lg={12}
          style={{ paddingTop: "180px" }}
        >
          <h2 className={classes.title90}>CONTACT US</h2>
        </Grid>
      </Grid>
      <div align="center" style={{ marginBottom: "60px" }}>
        <Hidden only={["md", "xl", "lg"]}>
          <LoadScript googleMapsApiKey="AIzaSyDh0cT_uufKW_zVoXuPZWmuHoeGm25uD14">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={9.7}
              options={{
                zoomControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                draggable: false,
                mapTypeControl: false,
              }}
            >
              <Marker
                position={{ lat: 43.8466014, lng: -79.3661062 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.8466014,-79.3661062,15z/data=!4m5!3m4!1s0x0:0x50755936aae62acf!8m2!3d43.8466014!4d-79.3661062")
                }
              />
              <Marker
                position={{ lat: 43.8341133, lng: -79.5067679 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.8341133,-79.5067679,15z/data=!4m2!3m1!1s0x0:0xdff9747edc0520cf?sa=X&ved=2ahUKEwiFq6-XqbztAhVic98KHZO_Av0Q_BIwCnoECBMQBQ")
                }
              />
              <Marker
                position={{ lat: 43.6383981, lng: -79.4213668 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.6383981,-79.4213668,15z/data=!4m2!3m1!1s0x0:0x4897ada6f7bece85?sa=X&ved=2ahUKEwjz3u7eqrztAhWonOAKHcEFBZcQ_BIwCnoECBUQBQ")
                }
              />
              <Marker
                position={{ lat: 43.7641044, lng: -79.3191238 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.7641044,-79.3191238,15z/data=!4m2!3m1!1s0x0:0x20c86b0693c7f4b5?sa=X&ved=2ahUKEwj1ip_lrbztAhXcFFkFHUSpD_MQ_BIwCnoECBEQBQ")
                }
              />
              <Marker
                position={{ lat: 43.3717453, lng: -79.7838788 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.3717453,-79.7838788,15z/data=!4m2!3m1!1s0x0:0x3e52c9ca5f74d81?sa=X&ved=2ahUKEwigipaCrrztAhX1FVkFHfzUDcgQ_BIwCnoECBMQBQ")
                }
              />
              <Marker
                position={{ lat: 43.5977499, lng: -79.7455797 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.5977499,-79.7455797,15z/data=!4m2!3m1!1s0x0:0x2e88d2a7cc77fe89?sa=X&ved=2ahUKEwjPuKKTrrztAhWyElkFHY3ODDsQ_BIwCnoECBIQBQ")
                }
              />
              <Marker
                position={{ lat: 43.813538, lng: -79.2922387 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.813538,-79.2922387,15z/data=!4m2!3m1!1s0x0:0x6efe437728cf7044?sa=X&ved=2ahUKEwistImsrrztAhURFFkFHfl2CmAQ_BIwCnoECBAQBQ")
                }
              />
              <Marker
                position={{ lat: 43.6642183, lng: -79.4561492 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.6642183,-79.4561492,15z/data=!4m2!3m1!1s0x0:0x60f8982dac6087fb?sa=X&ved=2ahUKEwjBu6fArrztAhVfF1kFHVTJAB4Q_BIwCnoECBUQBQ")
                }
              />
            </GoogleMap>
          </LoadScript>
        </Hidden>
        <Hidden only={["xs", "sm"]}>
          <LoadScript googleMapsApiKey="AIzaSyDh0cT_uufKW_zVoXuPZWmuHoeGm25uD14">
            <GoogleMap
              mapContainerStyle={containerStyleDesktop}
              center={center}
              zoom={9.7}
              options={{
                zoomControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                draggable: false,
                mapTypeControl: false,
              }}
            >
              <Marker
                position={{ lat: 43.8466014, lng: -79.3661062 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.8466014,-79.3661062,15z/data=!4m5!3m4!1s0x0:0x50755936aae62acf!8m2!3d43.8466014!4d-79.3661062")
                }
              />
              <Marker
                position={{ lat: 43.8341133, lng: -79.5067679 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.8341133,-79.5067679,15z/data=!4m2!3m1!1s0x0:0xdff9747edc0520cf?sa=X&ved=2ahUKEwiFq6-XqbztAhVic98KHZO_Av0Q_BIwCnoECBMQBQ")
                }
              />
              <Marker
                position={{ lat: 43.6383981, lng: -79.4213668 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.6383981,-79.4213668,15z/data=!4m2!3m1!1s0x0:0x4897ada6f7bece85?sa=X&ved=2ahUKEwjz3u7eqrztAhWonOAKHcEFBZcQ_BIwCnoECBUQBQ")
                }
              />
              <Marker
                position={{ lat: 43.7641044, lng: -79.3191238 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.7641044,-79.3191238,15z/data=!4m2!3m1!1s0x0:0x20c86b0693c7f4b5?sa=X&ved=2ahUKEwj1ip_lrbztAhXcFFkFHUSpD_MQ_BIwCnoECBEQBQ")
                }
              />
              <Marker
                position={{ lat: 43.3717453, lng: -79.7838788 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.3717453,-79.7838788,15z/data=!4m2!3m1!1s0x0:0x3e52c9ca5f74d81?sa=X&ved=2ahUKEwigipaCrrztAhX1FVkFHfzUDcgQ_BIwCnoECBMQBQ")
                }
              />
              <Marker
                position={{ lat: 43.5977499, lng: -79.7455797 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.5977499,-79.7455797,15z/data=!4m2!3m1!1s0x0:0x2e88d2a7cc77fe89?sa=X&ved=2ahUKEwjPuKKTrrztAhWyElkFHY3ODDsQ_BIwCnoECBIQBQ")
                }
              />
              <Marker
                position={{ lat: 43.813538, lng: -79.2922387 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.813538,-79.2922387,15z/data=!4m2!3m1!1s0x0:0x6efe437728cf7044?sa=X&ved=2ahUKEwistImsrrztAhURFFkFHfl2CmAQ_BIwCnoECBAQBQ")
                }
              />
              <Marker
                position={{ lat: 43.6642183, lng: -79.4561492 }}
                onClick={() =>
                  (window.location.href =
                    "https://www.google.com/maps/place/GoPro+Plumbing/@43.6642183,-79.4561492,15z/data=!4m2!3m1!1s0x0:0x60f8982dac6087fb?sa=X&ved=2ahUKEwjBu6fArrztAhVfF1kFHVTJAB4Q_BIwCnoECBUQBQ")
                }
              />
            </GoogleMap>
          </LoadScript>
        </Hidden>
      </div>
    </div>
  );
}
