import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components

import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import SEO from "../components/SEO/SEO.jsx";
// core components
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer.jsx";

import { GatsbySeo } from "gatsby-plugin-next-seo";

import HeaderLinks from "../components/Header/HeaderLinks.jsx";
import Intro from "../pages-sections/Contact/Intro.jsx";

import Contact from "../pages-sections/Contact/Contact.jsx";

import LeakDetection from "../pages-sections/Contact/LeakDetection.jsx";

import {
  title,
  container,
  whiteColor,
  main,
  mainRaised,
} from "../jss/nextjs-material-kit-pro.js";
// Sections for this page
const useStyles = makeStyles({
  container: {
    ...container,
    zIndex: "10",
    color: "#FFFFFF",
    position: "relative",
  },

  mainRaised: {
    ...main,
    ...mainRaised,
  },
  parallax: {
    height: "70vh",
    "@media screen and (max-width:500px)": {
      height: "60vh",
    },
  },
  call: {
    fontSize: "2rem",
    textDecoration: "underline",
    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      textAlign: "center",
      fontSize: "27px",
      marginLeft: "-10px",
      marginTop: "20px",
    },
    "&:hover": {
      color: "#0099CC",
      textDecoration: "underline",
    },
  },
  title: {
    ...title,
    color: whiteColor + "  !important",
    marginBottom: "25px",
    fontWeight: "500",
    fontSize: "4rem",
    textAlign: "center",
    fontFamily: "poppins",
    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      fontSize: "34px",
      marginTop: "120px",
    },
  },
  title3: {
    ...title,
    textAlign: "center",
    fontWeight: "500",
    fontFamily: "poppins",
    color: whiteColor + "  !important",
    marginBottom: "25px",
    fontSize: "1.5rem",
    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      textAlign: "center",
      fontSize: "27px",
      marginLeft: "-10px",
      marginTop: "20px",
    },
  },
  title2: {
    ...title,
    textAlign: "center",
    fontWeight: "500",
    fontFamily: "poppins",
    color: whiteColor + "  !important",
    marginBottom: "25px",
    fontSize: "2rem",
    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      textAlign: "center",
      fontSize: "27px",
      marginLeft: "-10px",
      marginTop: "20px",
    },
  },
  phoneTitle: {
    ...title,
    textAlign: "center",
    fontWeight: "500",
    fontFamily: "poppins",
    color: whiteColor + "  !important",
    marginBottom: "25px",
    fontSize: "1.5rem",
    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      textAlign: "center",
      fontSize: "27px",
      marginLeft: "-10px",
      marginTop: "20px",
    },
  },
  container2: {
    position: "absolute",
    width: "auto",
    height: "auto",
    top: "20%",
    left: "20%",
    textAlign: "center",
    marginTop: "-25px",
    "@media only screen and (min-width: 768px) and (max-height: 1024px)": {
      width: "auto",
      height: "auto",
      left: "10%",
      position: "absolute",
    },
    "@media only screen and (min-device-width: 1570px) and (max-height: 1570px)": {
      width: "auto",
      height: "auto",
      left: "40%",
      position: "absolute",
    },
    "@media only screen and (min-device-width: 1366px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)": {
      width: "auto",
      height: "auto",
      left: "37%",
      position: "absolute",
    },
    "@media only screen and (max-width: 500px)": {
      width: "auto",
      top: "5%",
      height: "auto",
      left: "10%",
      position: "absolute",
    },
  },
  blackOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    height: "100%",
    paddingTop: "20px",
    color: "white",
  },
});

export default function Home(props) {
  const classes = useStyles();

  return (
    <div>
      <GatsbySeo
        title="Contact Us | GoPro Plumbing"
        description="GoPro Plumbing is the place for the all general plumbing services. You can contact us by calling us on 647-370-9868 or visit our website today."
        canonical="https://goproplumbing.ca/contact-us/"
      />
      <Header
        color="dark"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
      />
      <SEO />

      <div
        className={classNames(classes.main, classes.mainRaised)}
        id="main-panel"
      >
        <Intro />

        <LeakDetection />
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
